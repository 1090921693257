import {styled, Typography} from '@mui/material';
import {
  Datagrid,
  EditButton,
  FunctionField,
  Labeled,
  Pagination,
  ReferenceManyField,
  SelectField,
  TextField,
} from 'react-admin';

import {Partner, PermissionDto, Product} from '../../api/generated';
import {CustomShow} from '../../components/CustomShow';
import {LabeledDateField} from '../../components/Fields/LabeledDateField';
import {LabeledTextField} from '../../components/Fields/LabeledTextField';
import {PermissionGuard} from '../../components/PermissionGuard';
import {PermissionLink} from '../../components/PermissionLink';
import {RenderConditionalLabel} from '../../components/RenderLabel';
import {usePermissions} from '../../hooks/usePermissions';
import {RoundedImageField} from './helpers';

const StyledPaperContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '7px',
  height: '250px',
});

const CardWithChip = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '30px',
});

const content = [
  {
    content: (
      <div>
        <CardWithChip>
          <Typography variant="h1" color="secondary">
            Partenaire
          </Typography>
          <FunctionField
            key="isDisplayed"
            source="isDisplayed"
            render={(record: Partner): JSX.Element => (
              <RenderConditionalLabel
                condition={record.isDisplayed}
                trueText="visible"
                falseText="invisible"
              />
            )}
          />
        </CardWithChip>
        <StyledPaperContent>
          <LabeledTextField
            key="datamatrixPartnerId"
            source="datamatrixPartnerId"
          />
          <Labeled>
            <SelectField
              key="datamatrixSignatureConfig"
              source="datamatrixSignatureConfig"
              choices={[
                {id: 'ALLOW_BOTH', name: 'Clair & Signé'},
                {id: 'ONLY_ALLOW_SIGNED', name: 'Signé uniquement'},
                {id: 'ONLY_ALLOW_UNSIGNED', name: 'Clair uniquement'},
              ]}
            />
          </Labeled>
          <LabeledTextField key="name" source="name" />
          <LabeledTextField
            key="displayOrderInApp"
            source="displayOrderInApp"
          />
          <LabeledDateField
            key="createdAt"
            source="createdAt"
            showTime
            locales="fr-FR"
          />
          <Typography color="gray" key="sectionTitle">
            Identifiants API rapports d&apos;émargement
          </Typography>
          <LabeledTextField
            key="partnerAuthUsername"
            source="partnerAuth.username"
          />
        </StyledPaperContent>
      </div>
    ),
  },
  {
    title: 'Logo',
    content: (
      <StyledPaperContent>
        <FunctionField
          key="logo"
          render={(record: Partner): JSX.Element => {
            if (record.logo) {
              return <RoundedImageField label="" source="logo" />;
            }
            return (
              <>
                Pour ajouter un logo, cliquer sur modifier puis déposer
                l&apos;image à téléverser ou cliquer pour la sélectionner
              </>
            );
          }}
        />
      </StyledPaperContent>
    ),
  },
];

const productGrid = (
  <ReferenceManyField
    pagination={<Pagination />}
    perPage={10}
    reference="Product"
    target="partnerIds">
    <Datagrid bulkActionButtons={false}>
      <FunctionField
        source="name"
        label="Libellé produit"
        render={(record: Product): JSX.Element => (
          <PermissionLink
            permission={PermissionDto.ProductRead}
            to={`/admin/Product/${record.id}/show`}>
            {record.name}
          </PermissionLink>
        )}
      />
      <TextField label="Catégorie produit" source="category.label" />
      <FunctionField
        source="active"
        render={(record: Product): JSX.Element => (
          <RenderConditionalLabel condition={record.active} />
        )}
      />
    </Datagrid>
  </ReferenceManyField>
);

export const PartnerShow = (): JSX.Element => {
  const {can} = usePermissions();

  const actions: JSX.Element[] = [];

  if (can(PermissionDto.PartnerWrite)) {
    actions.push(<EditButton key="EditButton" />);
  }

  return (
    <PermissionGuard permission={PermissionDto.PartnerRead}>
      <CustomShow
        actions={actions}
        topPapers={content}
        mainPaperTitle="Produits"
        mainPaper={[
          {
            content: productGrid,
          },
        ]}
      />
    </PermissionGuard>
  );
};
